// See ReactFrontendSettings interface for what should and can be added
const buildType = document.currentScript?.getAttribute('data-build-type');

const trezorVersion = 9;

const baseConfig = {
    trezorConnectUrl: `https://connect.trezo.io/${trezorVersion}/`,
    trezorDevMail: 'tomas@simplecoin.eu',
    socials: ['google', 'linkedin', 'facebook'],
    buildType: buildType,
};

const baseDevConfig = {
    ...baseConfig,
    environment: 'development',
    googleTag: 'GTM-N5CQ4GT',
    exchangeUrl: 'https://d1-exchange.simplecoin.dev',
    walletUrl: 'https://d1-wallet.simplecoin.dev',
    landingUrl: 'https://d1-www.simplecoin.dev',
    blogApiUrl: 'https://d1-blog.simplecoin.dev/graphql',
    blogUrl: 'https://d1-blog.simplecoin.dev',
    preferredCountries: ['cz', 'sk', 'pl', 'de', 'ru', 'ua'],
    recaptchaSiteKey: '6LeBglcnAAAAAIqspa2nyawQ8QuCFAEs8o4uqpus',
};

let config;

config = {
    ...baseDevConfig,
    brand: buildType === 'wallet' ? 'Simplecoin Wallet' : 'Simplecoin Exchange',
    appUrl: buildType === 'wallet' ? baseDevConfig.walletUrl : baseDevConfig.exchangeUrl,
    apiUrl: buildType === 'wallet' ? baseDevConfig.walletUrl + '/v1' : baseDevConfig.exchangeUrl + '/v1',
    frontEndType: buildType,
};

window.config = config;
